import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  components: {
    CurrencySwitcher: {
      dropdown: true,
      enable: true,
    },
    HeaderMain: {
      showContentColumn: false,
    },
    MainMenu: {
      showDropdownIndicator: false,
    },
    LanguageSwitcher: {
      enable: true,
      dropdown: false,
      showActiveLanguage: true,
    },
    PageHomepage: {
      mainHeadingPosition: 'belowSlider', // aboveSlider, belowSlider
      showHomepageBranches: false,
      showHomepageBrands: true,
      showHomepageTextBlock: true,
      showProductsRecommended: true,
    },
    PageProductDetail: {
      files: {
        enable: true,
      },
    },
    Slider: {
      cmsTextContent: {
        enable: true,
      },
    },
    UniqueSellingPoints: {
      parentClass: 'row mb-4 mb-md-5 justify-content-center',
    },
  },
}

export default merge(themeSettings, overloadSettings)
