<script>
export default {
  methods: {
    fileName(path) {
      const parts = path.split('/')
      return parts[parts.length - 1]
    },
    fileType(path) {
      const parts = path.split('.')
      return parts[parts.length - 1]
    },
    cmsFileUrl(path) {
      return [this.$env.CMS_URL, 'upload', path].join('/')
    },
    downloadableFileName(path) {
      const parts = path.split('/')
      let name = parts[parts.length - 1] || undefined

      if (name) {
        name = this.slugifyFilePath(name)
      }

      return name
    },
    /**
     * Converts a given text into a slug format.
     *
     * @param {string|number} path - The text to be slugified.
     * @return {string} - The slugified version of the text.
     */
    slugifyFilePath(path) {
      return (
        path
          .toString() // Cast to string
          .toLowerCase() // Convert the string to lowercase letters
          .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
          .trim() // Remove whitespace from both sides of a string
          .replace(/\s+/g, '-') // Replace spaces with -
          // eslint-disable-next-line no-useless-escape
          .replace(/\-\-+/g, '-')
      )
    },
  },
}
</script>
